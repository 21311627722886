import React from "react"

import "../styles/components/Footer.styl"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons"

export default function Footer() {
  return (
    <section className="Footer " id="Footer">
      <div
        className="footer__logo"
        sal="zoom-out"
        data-sal-delay="100"
        data-sal-easing="ease"
      >
        <StaticImage
          alt="rounded blue ball ant PHOTON word"
          src="../images/logos/photon-logo-blue.jpg"
          objectFit="contain"
          quality="100"
        />
      </div>
      <div
        className="footer__info"
        data-sal="zoom-in"
        data-sal-delay="100"
        data-sal-easing="ease"
      >
        <div className="info__contact info--grid">
          <div className="contact__title --semibold-blue">Contáctanos</div>
          <div className="contact__tel">
            Tel:
            <a href="tel:444-962-86-75" rel="noopener noreferrer">
              {" "}
              444 962 86 75
            </a>
            <br />
            Whatsapp:
            <a
              href="https://api.whatsapp.com/send?phone=+524445541919&text=Me%20gustar%C3%ADa%20saber%20m%C3%A1s%20de%20sus%20productos"
              target="__blank"
              rel="noopener noreferrer"
            >
              444 554 19 19
            </a>
            <br />
            Email:
            <a
              href="mailto: ventas@photonsolar.com"
              className="contact__email"
              rel="noopener noreferrer"
            >
              ventas@photonsolar.com
            </a>
          </div>
        </div>
        <address className="info__address info--grid">
          <div className="address__title --semibold-blue">
            Dirección: <br />
            <span className="address__subtitle">
              Genaro Codina. #180-5 / Col. Jardines del Estadio C.P. 78280 San
              Luis Potosí, S. L. P México
            </span>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d923.9081312082993!2d-100.98924057081085!3d22.13998239908794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842a9f4cdd5e0127%3A0x84bdf268c6a44150!2sPHOTON%20Energ%C3%ADas%20Sustentables!5e0!3m2!1ses!2smx!4v1623767694511!5m2!1ses!2smx"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            title="Photon Solar"
            className="address__map"
          ></iframe>
        </address>
        <div className="info__schedule info--grid">
          <div className="schedule__title --semibold-blue">
            Horario de atención
          </div>
          <div className="schedule__text">
            Lunes - Viernes: <br />
            <div className="pl-2">
              9:00am - 2:00pm <br /> 4:00pm - 6:00 pm
            </div>
            Sábado: <br />
            <div className="pl-2">9:00am - 1:00pm</div>
            <br /> Domingo: Cerrado
          </div>
        </div>
      </div>
      <div className="info__social social">
        <a
          href="https://www.facebook.com/www.photonsolar.com.mx/"
          rel="noopener noreferrer"
          target="_blank"
          className=" social__icon"
        >
          <FontAwesomeIcon icon={faFacebook} alt="facebook icon" />
        </a>
        <a
          href="https://twitter.com/photon_es?lang=es"
          className=" social__icon"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} alt="twitter icon" />
        </a>
      </div>
      <div className="footer__rights">
        Algunos derechos reservados, Photon Energías Sustentables © 2021
      </div>
    </section>
  )
}
